<template>
  <div>
    <Intro />

    <div class="text-center mb-5">
      <a class="btn btn-primary" @click.prevent="goToStep(1)">Inizia la compilazione</a>
    </div>
  </div>
</template>

<script>

import goToStepMixin from '@/mixins/goToStepMixin';
import Intro from '@/views/components/Intro.vue';

export default {
  mixins: [goToStepMixin],
  components: {
    Intro,
  },
};

</script>

<style lang="scss">
</style>
